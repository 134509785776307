.sidebar_secondary {
    --sidebar-width: 210px;
    --sidebar-height: 100%;
    --sidebar-min-height: 100%;
    --sidebar-background-color: var(--color-white);
    --header-padding: var(--spacing-5) var(--spacing-4) var(--spacing-4);
    --header-background-color: var(--color-white);
    --footer-padding: var(--spacing-6) var(--spacing-4) var(--spacing-5);
    --footer-background-color: var(--color-white);
    --navigation-item-background-color: var(--color-white);
    --navigation-item-background-color-hover: var(--color-blue-200);
    --navigation-item-color: var(--color-black);

    z-index: var(--z-sidebar-secondary);
    padding-inline: var(--spacing-0);
    border-right: var(--border-width-1) solid var(--color-gray-300);

    // Content
    > div:first-child {
        position: relative;
        overflow-y: hidden;
    }

    .list {
        list-style-type: none;
        margin: var(--spacing-0);
        padding: var(--spacing-0);

        > li {
            margin-bottom: var(--spacing-3);

            &:last-child {
                margin-bottom: var(--spacing-0);
            }
        }
    }

    // Footer
    > div:last-child {
        color: var(--color-black);
    }
}
